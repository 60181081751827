import NextHead from 'next/head';
import { ReactNode, useEffect, useState } from 'react';

type HeadProps = {
  title?: string;
  children?: ReactNode;
};

export const Head = ({ title, children }: HeadProps) => {
  const defaultTitle = `Abhishek Panthee - ${title ?? 'Portfolio'}`;
  const attentionMessage = 'Come back';
  const [pageTitle, setPageTitle] = useState(defaultTitle);
  const [favicon, setFavicon] = useState('/favicon.ico');

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setPageTitle(attentionMessage);
        setFavicon('/favicon.ico');
      } else {
        setPageTitle(defaultTitle);
        setFavicon('/favicon.ico');
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [defaultTitle]);

  return (
    <NextHead>
      <title>{pageTitle}</title>
      <link rel="icon" href={favicon} />
      {children}
    </NextHead>
  );
};
